<template>
  <v-data-table
    :headers="headers"
    :items="periodos"
    :items-per-page="5"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title> Configuración periodos </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <template>
          <v-dialog v-model="dialogAnual" max-width="290">
            <v-card>
              <v-card-title>
                <span class="headline">Nuevo año</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="defaultAnual.fechaAnual"
                        label="Año"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="defaultAnual.cantidadPeriodos"
                        label="Peridos"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogAnual = false">
                  Cancelar
                </v-btn>
                <v-btn
                  :disabled="btnAnualDisa"
                  color="blue darken-1"
                  text
                  @click="saveAnual"
                >
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="secondary" class="mb-2" v-bind="attrs" v-on="on">
                Nuevo periodo
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-autocomplete
                        :items="anual"
                        item-text="fechaAnual"
                        item-value="id"
                        label="Años"
                        v-model="editedItem.periodoAnualId"
                        append-outer-icon="mdi-plus-outline"
                        @click:append-outer="dialogAnual = true"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedItem.nombre"
                        label="Nombre"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedItem.alias"
                        label="Nombre impresion"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedItem.fechaInicio"
                        label="Fecha inicio"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedItem.fechaFin"
                        label="Fecha fin"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedItem.procentaje"
                        label="Porcentjate %"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-autocomplete
                        v-model="editedItem.estado"
                        :items="estados"
                        label="Estado"
                        item-text="nombre"
                        item-value="id"                        
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-switch v-model="editedItem.descargaBoletinActivo" label="Habilitar Descarga Boletín Estudiantes" class="mt-4"></v-switch>                      
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancelar
                </v-btn>
                <v-btn color="blue darken-1" text @click="save">
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-toolbar>
    </template>
    <template v-slot:[`item.descargaBoletinActivo`]="{ item }">
      <v-simple-checkbox v-model="item.descargaBoletinActivo" disabled ></v-simple-checkbox>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize"> Actualizar </v-btn>
    </template>
  </v-data-table>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import Calendario from "@/components/Calendario.vue";
export default {
  components: {
    Calendario,
  },

  data: () => ({
    dialogAnual: false,
    dialog: false,
    btnAnualDisa: false,
    estados: [
      { id: 1, nombre: "Abierto" },
      { id: 0, nombre: "Cerrado" },
    ],
    headers: [
      {
        text: "Nombre",
        value: "nombre",
      },
      {
        text: "Fecha incio",
        align: "start",
        value: "fechaInicio",
      },
      { text: "Fecha Fin", value: "fechaFin" },
      { text: "Porcentaje", value: "procentaje" },
      { text: "Año", value: "fechaAnual" },
      { text: "Descarga Boletín", value: "descargaBoletinActivo" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    periodos: [],
    editedIndex: -1,
    editedItem: {
      id: 0,
      nombre: "",
      fechaInicio: "",
      fechaFin: "",
      procentaje: 0,
      periodoAnualId: 0,
      alias: "",
      estado: 1,
      descargaBoletinActivo:false
    },
    defaultItem: {
      id: 0,
      nombre: "",
      fechaInicio: "",
      fechaFin: "",
      procentaje: 0,
      periodoAnualId: 0,
      alias: "",
      estado: 1,
      descargaBoletinActivo:false
    },
    defaultAnual: {
      fechaAnual: new Date(Date.now()).getFullYear(),
      cantidadPeriodos: 4,
    },
  }),

  computed: {
    ...mapGetters(["anual"]),
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo periodo" : "Editar periodo";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions([
      "GetAnuales",
      "GetPeriodos",
      "RegistrarAnual",
      "RegistrarPeriodo",
      "UpdatePeriodo",
      "ModificarMensaje",
    ]),
    ...mapMutations(["ADD_ANUALES"]),
    initialize() {
      this.GetAnuales();
      this.GetPeriodos().then((x) => (this.periodos = x.data));
    },
    editItem(item) {
      this.editedIndex = this.periodos.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        const index = this.editedIndex;
        const item = this.editedItem;
        //editar
        this.UpdatePeriodo(this.editedItem).then((x) => {
          Object.assign(this.periodos[index], item);
          this.ModificarMensaje({ texto: "Actualización Exitosa!" });
        });
      } else {
        //nuevo

        this.RegistrarPeriodo(this.editedItem).then((x) => {
          const respuesta = x.data;
          if (!!respuesta) {
            this.periodos.push(respuesta);
            this.ModificarMensaje({ texto: "Registro Exitoso!" });
          }
        });
      }
      this.close();
    },
    saveAnual() {
      this.btnAnualDisa = true;
      this.RegistrarAnual(this.defaultAnual).then((x) => {
        const respuesta = x.data;
        if (!!respuesta) {
          this.btnAnualDisa = false;
          this.ADD_ANUALES(respuesta);
          this.dialogAnual = false;
        }
      });
    },
  },
};
</script>
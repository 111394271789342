<template>
    <v-container>
        <Periodos></Periodos>
    </v-container>
</template>
<script>
import Periodos from "@/components/Periodos.vue"
export default {
    components: {
        Periodos
    }
}
</script>